import { User } from "firebase/auth";
import {
  setDoc,
  doc,
  getDoc,
  addDoc,
  collection,
  getDocs,
  query,
  where,
  updateDoc
} from "firebase/firestore";
import { Toast } from "../components/Alerts/alertservice";
import { PunchEnum } from "../constants/enums";
import { db } from "../firebase";
import { PunchType } from "../pages/app/Punch";
import { SignupFormType } from "../pages/auth/Signup";

export type EmployeeDocumentType = {
  first_name: string;
  last_name: string;
  email: string;
  email_verified: boolean;
  role: string;
  photoURL: string;
  department: string;
};

export type AttendanceDocumentType = {
  user: EmployeeDocumentType;
  selfieURL: string;
  coords: {
    lat: number;
    lng: number;
  };
  location_address: string;
  punch_type: "checkin" | "checkout";
  date: Date;
  time: Date;
};

// creates user document
export const createUserDocument = async (
  user: User,
  photoURL: any,
  values: SignupFormType
) => {
  try {
    // Add a new document in collection "employees"
    await setDoc(doc(db, "employees", user.uid), {
      _id: user.uid,
      first_name: values.first_name,
      last_name: values.last_name,
      email: user.email,
      email_verified: user.emailVerified,
      role: values.role,
      photoURL: photoURL,
      department: values.department
    });
  } catch (err) {
    console.log(err);
    return;
  }
};

export const getUserDocument = async (user: any) => {
  try {
    const docRef = doc(db, "employees", user?.currentUser.uid);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      console.log("Document data:", docSnap.data());
      return docSnap.data();
    } else {
      // doc.data() will be undefined in this case
      console.log("No such document!");
      return;
    }
  } catch (err) {
    console.log(err);
    return;
  }
};

// get attendance data by user id
export const getAllUsersAttendance = async (user: any) => {
  // create ref
  const attendanceRef = collection(db, "attendance");
  // get documents
  const attendanceDocs = await getDocs(attendanceRef);
  const attendanceData: any = [];

  // store document
  attendanceDocs.forEach((doc) => {
    attendanceData.push(doc.data());
  });

  return attendanceData;
};

// get attendance data by user id
export const getAttendanceByUserId = async (user: any, uid?: any) => {
  // create ref
  const attendanceRef = collection(db, "attendance");
  // query data
  const queryData = query(
    attendanceRef,
    where("user._id", "==", uid ? uid : user.uid)
  );
  // get document
  const attendanceDocs = await getDocs(queryData);
  const attendanceData: any = [];

  // store document
  attendanceDocs.forEach((doc) => {
    attendanceData.push({
      id: doc.id,
      ...doc.data()
    });
  });

  return attendanceData;
};

// creates attendance document
export const createAttendanceDocument = async (user: any, punch: any) => {
  try {
    const payload: any = {
      user: punch.user,
      selfieURL: punch.selfieURL,
      coords: punch.coords,
      location_address: punch.location_address,
      punch_type: punch.punch_type,
      date: punch.date,
      time: punch.time
    };

    if (punch.punch_type === PunchEnum.checkin) {
      payload.checkoutTime = null;
    }

    if (punch.punch_type === PunchEnum.checkout) {
      const checkedInAttendanceDoc = await getAttendanceByUserId(
        user,
        user.currentUser.uid
      );

      let relevantCheckedInDoc = checkedInAttendanceDoc.filter(
        (data: any) =>
          new Date(data?.date).toLocaleDateString() ===
            new Date(punch?.date).toLocaleDateString() &&
          data?.punch_type === PunchEnum.checkin
      );

      if (relevantCheckedInDoc?.length > 0) {
        const checkedInDocRef = doc(
          db,
          "attendance",
          relevantCheckedInDoc[0].id
        );

        // Set the "checkoutTime" field of the city 'DC'
        await updateDoc(checkedInDocRef, {
          checkoutTime: punch.time
        });
      }
    }

    // Add a new document in collection "employees"
    await addDoc(collection(db, "attendance"), payload);
  } catch (err) {
    console.log(err);
    return;
  }
};
