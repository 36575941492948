import { Button, Typography } from "@mui/material";
import { format } from "date-fns";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import moment from "moment";
import React from "react";
import { useNavigate } from "react-router-dom";
import { SelfiePlaceholder } from "../../assets/default.exports";
import BasicHeader from "../../components/Headers/BasicHeader";
import { PunchEnum } from "../../constants/enums";
import { auth } from "../../firebase";
import { getAttendanceByUserId } from "../../firebase/documents";
import { getUserFromLocalStorage } from "../../firebase/localStorage";

type Props = {};

const Home = (props: Props) => {
  const [currentUser] = React.useState(getUserFromLocalStorage());
  const [currentDay] = React.useState(format(new Date(), "EEEE"));
  const [currentDate] = React.useState(format(new Date(), "PPP"));
  const [attendanceData, setAttendanceData] = React.useState<any>(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const navigate = useNavigate();

  const punchCheckin = () => {
    navigate(`/punch/${PunchEnum.checkin}`);
  };

  const punchCheckout = () => {
    navigate(`/punch/${PunchEnum.checkout}`);
  };

  React.useEffect(() => {
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        const attendanceData = await getAttendanceByUserId(user);
        setAttendanceData(attendanceData);
      }
    });
  }, []);

  return (
    <div>
      <BasicHeader
        showAppLogo={true}
        title="Home"
        fixed={true}
        menuButton={true}
      />
      <div className="p-3">
        <section>
          <Typography variant="h5" fontWeight={"bold"}>
            {currentDay}
          </Typography>
          <Typography variant="h6" fontWeight={"bold"}>
            {currentDate}
          </Typography>
        </section>
        <section>
          <div className="flex items-center justify-center mt-[40px] flex-col gap-[10px]">
            <img
              src={currentUser?.photoURL}
              width={150}
              height={150}
              onError={(ele) => {
                ele.currentTarget.src = SelfiePlaceholder;
              }}
              className="rounded-full h-[150px] w-[150px] object-cover"
              alt=""
            />
            <Typography variant="h6">{currentUser?.displayName}</Typography>
          </div>
          <div className="flex flex-col items-center gap-[15px] mt-[30px]">
            <Button
              fullWidth
              variant="contained"
              size="large"
              color="primary"
              className=""
              onClick={punchCheckin}
            >
              Checkin
            </Button>
            <Button
              fullWidth
              variant="contained"
              size="large"
              color="secondary"
              onClick={punchCheckout}
            >
              Checkout
            </Button>
          </div>
        </section>
        <section>
          <div className="mt-[20px]">
            <Typography variant="body1" fontWeight={"semibold"}>
              Today's activity:
            </Typography>
            <div className="mt-[10px]">
              {attendanceData &&
                attendanceData.map((data: any, index: number) => {
                  const bgTypeClass =
                    data?.punch_type === PunchEnum.checkin
                      ? "bg-green-500 bg-opacity-40"
                      : "bg-red-500 bg-opacity-40";
                  const punchTiming: any = moment(data?.time).format(
                    "hh:mm:ss"
                  );
                  const punchingDate: any = moment(data?.date).format(
                    "DD-MM-YYYY"
                  );

                  const isTodaysTransaction = () => {
                    let currentDate: any = moment().format("DD-MM-YYYY");

                    if (currentDate === punchingDate && data?.time != null) {
                      return true;
                    } else {
                      return false;
                    }
                  };

                  return isTodaysTransaction() ? (
                    <div
                      key={index}
                      className={`${bgTypeClass} p-3 rounded-[5px] mb-[15px]`}
                    >
                      <div className="flex items-center">
                        <img
                          src={data?.selfieURL}
                          className={
                            "h-[50px] w-[50px] object-cover rounded-[5px] mr-[10px]"
                          }
                          alt=""
                        />
                        <div>
                          <Typography
                            fontWeight={"bold"}
                            className="capitalize"
                          >
                            {data?.punch_type}
                          </Typography>
                          <Typography fontWeight={"bold"}>
                            {punchTiming}
                          </Typography>
                        </div>
                      </div>
                    </div>
                  ) : null;
                })}
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Home;
