import {
  Close,
  Logout,
  Menu,
  ArrowBack,
  AccountCircle
} from "@mui/icons-material";
import { Button, IconButton, Typography } from "@mui/material";
import { getAuth } from "firebase/auth";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { MahavirLogo, SelfiePlaceholder } from "../../assets/default.exports";
import { auth } from "../../firebase";
import { getUserFromLocalStorage } from "../../firebase/localStorage";
import { app_routes } from "../../pages/Routes";

type Props = {
  title: string;
  showAppLogo?: boolean;
  isCancel?: boolean;
  isGoBack?: boolean;
  goBackAction?: any;
  cancelAction?: any;
  fixed?: boolean;
  menuButton?: boolean;
};

const BasicHeader = (props: Props) => {
  const [sidebarVisisble, setSidebarVisible] = React.useState(false);
  const [currentUser] = useState(getUserFromLocalStorage());
  const navigate = useNavigate();

  const toggleSidebar = () => {
    setSidebarVisible(!sidebarVisisble);
  };

  return (
    <>
      {/* Header */}
      <div
        className={`h-[65px] bg-white shadow-md p-3 flex items-center justify-between ${
          props.fixed && "fixed top-0 left-0 right-0"
        }`}
      >
        {!props.showAppLogo ? (
          <div className="flex items-center">
            {props.isGoBack && (
              <IconButton
                color="inherit"
                aria-label="toggle password visibility"
                onClick={() => {
                  props.goBackAction();
                }}
                type="button"
              >
                <ArrowBack />
              </IconButton>
            )}
            {props.isCancel && (
              <IconButton
                color="inherit"
                aria-label="toggle password visibility"
                onClick={() => {
                  props.cancelAction();
                }}
                type="button"
              >
                <Close />
              </IconButton>
            )}
            <Typography
              variant="body1"
              fontWeight={"bold"}
              className="capitalize"
            >
              {props.title}
            </Typography>
          </div>
        ) : (
          <h1 className="text-red-700 font-bold text-2xl flex items-center justify-center">
            <img
              src={MahavirLogo}
              className={"h-[35px] w-[35px] mr-1"}
              alt=""
            />
            Mahavir
          </h1>
        )}
        {props.menuButton && (
          <IconButton
            onClick={() => {
              toggleSidebar();
            }}
          >
            <Menu className="text-black" />
          </IconButton>
        )}
      </div>
      {props.fixed && <div className="h-[65px]"></div>}
      {/* Sidemenu */}
      <>
        <div
          className={`app-sidebar fixed top-0 left-0 bottom-0 w-[280px] bg-white h-[100vh] z-30 shadow-lg ${
            sidebarVisisble && "visible"
          }`}
        >
          <div className="flex flex-col h-[100%]">
            <div className="p-3 flex flex-col gap-[10px] flex-1">
              <div className="flex items-center">
                <img
                  src={currentUser?.photoURL}
                  width={60}
                  height={60}
                  onError={(ele) => {
                    ele.currentTarget.src = SelfiePlaceholder;
                  }}
                  className="rounded-full h-[60px] w-[60px] object-cover"
                  alt=""
                />
                <div className="ml-2 overflow-hidden">
                  <h6 className="font-semibold text-[16px]">
                    {currentUser?.displayName}
                  </h6>
                  <h6 className="font-semibold text-ellipsis overflow-hidden text-sm">
                    {currentUser?.email}
                  </h6>
                </div>
              </div>
              <div className="border-b"></div>
              <div className="mt-2">
                <div className="flex flex-col gap-[10px]">
                  <Button
                    variant="outlined"
                    color="primary"
                    size={"large"}
                    startIcon={<AccountCircle />}
                    onClick={() => {
                      navigate(app_routes?.profile.relative_pathname);
                    }}
                  >
                    Profile
                  </Button>
                </div>
              </div>
            </div>
            <div className="p-3 flex flex-col gap-[10px]">
              <Button
                variant="contained"
                color="error"
                size={"large"}
                startIcon={<Logout />}
                onClick={() => {
                  getAuth().signOut();
                }}
              >
                Logout
              </Button>
            </div>
          </div>
        </div>
        <div
          onClick={() => {
            toggleSidebar();
          }}
          className={`app-sidebar-overlay bg-black bg-opacity-30 fixed left-0 right-0 top-0 bottom-0 z-20 ${
            sidebarVisisble && "visible"
          }`}
        ></div>
      </>
    </>
  );
};

export default BasicHeader;
