import { CameraAlt } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import React from "react";
import Webcam from "react-webcam";
import BasicHeader from "../Headers/BasicHeader";

type Props = {
  imageFile: any;
  cameraEnabled: any;
  setCameraEnabled: any;
  setImageFile: any;
  imageFileName: any;
};

const Camera = (props: Props) => {
  const webcamRef = React.useRef<React.LegacyRef<Webcam> | any>();
  const capture = React.useCallback(async () => {
    const imageBase64String = webcamRef.current.getScreenshot();
    await fetch(imageBase64String)
      .then((res) => res.blob())
      .then((blob) => {
        const file = new File([blob], props.imageFileName, {
          type: "image/png"
        });
        props.setImageFile({
          base64String: imageBase64String,
          file: file
        });
        props.setCameraEnabled(false);
      });
  }, [webcamRef]);

  const videoConstraints = {
    height: window.innerWidth,
    width: window.innerHeight,
    facingMode: "user"
  };

  React.useEffect(() => {
    const navigatorObj: any = navigator;
    navigatorObj.getUserMedia(
      // constraints
      {
        video: true,
        audio: true
      },
      function (localMediaStream: any) {},
      function (err: any) {
        console.log(err);
      }
    );
  }, []);

  return (
    <div className="fixed top-0 left-0 right-0 bottom-0 z-40 bg-white">
      <BasicHeader
        title="Take Selfie"
        isCancel={true}
        cancelAction={() => {
          props.setCameraEnabled(false);
        }}
      />
      <Webcam
        audio={false}
        ref={webcamRef}
        height={window.innerHeight}
        screenshotFormat="image/jpeg"
        videoConstraints={videoConstraints}
      />
      <div className="fixed bottom-0 left-0 right-0 h-[80px] flex items-center justify-center bg-black bg-opacity-[0.8]">
        <IconButton
          className="text-white"
          size="large"
          aria-label="toggle password visibility"
          onClick={() => {
            capture();
          }}
          type="button"
        >
          <CameraAlt className="text-white" fontSize="large" />
        </IconButton>
      </div>
    </div>
  );
};

export default Camera;
