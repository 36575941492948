import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
  Typography
} from "@mui/material";
import React from "react";
import { VisibilityOff, Visibility, Google } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { googleAuthAsync, manualSigninAsync } from "../../firebase/functions";
import BasicLoader from "../../components/Loaders/BasicLoader";
import { auth } from "../../firebase";
import { app_routes } from "../Routes";

type Props = {};

export type SigninFormType = {
  email: string;
  password: string;
};

export const SigninSchema = Yup.object().shape({
  email: Yup.string().email("Invalid Email").required("Email is required"),
  password: Yup.string()
    .min(6, "Too short")
    .max(20, "Too long")
    .required("Password is required")
});

const Signin = (props: Props) => {
  const [showPassword, setShowPassword] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [loadingMessage, setLoadingMessage] = React.useState("Loading...");
  const signinForm = useFormik({
    initialValues: {
      email: "",
      password: ""
    },
    validationSchema: SigninSchema,
    onSubmit: async (values) => {
      await manualSignin(values);
    }
  });

  const manualSignin = async (values: SigninFormType) => {
    // manual signup firebase service
    await manualSigninAsync(
      auth,
      values,
      isLoading,
      setIsLoading,
      loadingMessage,
      setLoadingMessage
    );
    // hide loader
    setIsLoading(false);
  };

  const googleSignin = async () => {
    // google signin firebase service
    await googleAuthAsync(
      auth,
      isLoading,
      setIsLoading,
      loadingMessage,
      setLoadingMessage
    );
    // hide loader
    setIsLoading(false);
  };

  return (
    <div className="p-3 pt-10">
      <Button
        variant="contained"
        size="large"
        fullWidth
        color="secondary"
        startIcon={<Google />}
        onClick={googleSignin}
      >
        Signin With Google
      </Button>
      <div className="flex items-center justify-center py-3">
        <div className="border border-b border-black w-full"></div>
        <div className="px-2">OR</div>
        <div className="border border-b border-black w-full"></div>
      </div>
      <form onSubmit={signinForm.handleSubmit}>
        <div className="flex flex-col gap-[20px]">
          <TextField
            label="Email"
            id="email"
            name="email"
            type={"email"}
            value={signinForm.values.email}
            onChange={signinForm.handleChange}
            onBlur={signinForm.handleBlur}
            helperText={
              signinForm.errors.email &&
              signinForm.touched.email &&
              signinForm.errors.email
            }
            error={
              signinForm.errors.email && signinForm.touched.email ? true : false
            }
            variant="outlined"
            fullWidth
          />
          <Box>
            <FormControl
              variant="outlined"
              fullWidth
              error={
                signinForm.errors.password && signinForm.touched.password
                  ? true
                  : false
              }
            >
              <InputLabel htmlFor="password">Password</InputLabel>
              <OutlinedInput
                id="password"
                name="password"
                type={showPassword ? "text" : "password"}
                fullWidth
                value={signinForm.values.password}
                onChange={signinForm.handleChange}
                onBlur={signinForm.handleBlur}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => {
                        setShowPassword(!showPassword);
                      }}
                      type="button"
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label="Password"
              />
            </FormControl>
            {signinForm.errors.password && signinForm.touched.password && (
              <div>
                <Typography
                  component="p"
                  variant="caption"
                  color={"error"}
                  ml="15px"
                  mt="3px"
                >
                  {signinForm.errors.password}
                </Typography>
              </div>
            )}
          </Box>
        </div>
        <div className="flex items-center justify-end mt-2">
          <Button variant="text">Forgot password?</Button>
        </div>
        <div className="mt-2">
          <Button
            variant="contained"
            size="large"
            fullWidth
            color="primary"
            type="submit"
          >
            Signin
          </Button>
        </div>
        <div className="flex items-center justify-center mt-2">
          <Typography color="primary" variant="body2">
            <Link to={app_routes?.signup.relative_pathname}>
              Don't have an account? Create one.
            </Link>
          </Typography>
        </div>
      </form>
      {isLoading && <BasicLoader loadingMessage={loadingMessage} />}
    </div>
  );
};

export default Signin;
