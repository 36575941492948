import { Typography } from "@mui/material";
import React from "react";
import { Routes as Pages, Route, Navigate } from "react-router-dom";
import {
  AppLayout,
  AuthLayout,
  Home,
  Profile,
  Punch,
  Signin,
  Signup
} from "./default.exports";

type Props = {};

export const app_routes = {
  root: {
    pathname: "/"
  },
  home: {
    pathname: "home",
    relative_pathname: "/home"
  },
  profile: {
    pathname: "profile",
    relative_pathname: "/profile"
  },
  punch: {
    pathname: "punch",
    relative_pathname: "/punch"
  },
  auth_layout: {
    pathname: "/auth",
    relative_pathname: "/auth"
  },
  signin: {
    pathname: "signin",
    relative_pathname: "/auth/signin"
  },
  signup: {
    pathname: "signup",
    relative_pathname: "/auth/signup"
  }
};

const Routes = (props: Props) => {
  return (
    <div>
      <Pages>
        <>
          {/* app routes */}
          <Route path={"/"} element={<AppLayout />}>
            <Route index element={<Navigate to={"home"} />} />
            <Route path={"home"} element={<Home />} />
            <Route path={"profile"} element={<Profile />} />
            <Route path={"punch"}>
              <Route path=":type" element={<Punch />} />
            </Route>
          </Route>
          {/* auth routes */}
          <Route path={"/auth"} element={<AuthLayout />}>
            <Route index element={<Navigate to={"signin"} />} />
            <Route path={"signin"} element={<Signin />} />
            <Route path={"signup"} element={<Signup />} />
          </Route>
        </>
      </Pages>
    </div>
  );
};

export default Routes;
