import {
  AddAPhoto,
  VisibilityOff,
  Visibility,
  CameraAlt as CameraIcon
} from "@mui/icons-material";
import {
  TextField,
  Box,
  Button,
  Typography,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton
} from "@mui/material";
import { useFormik } from "formik";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { SelfiePlaceholder } from "../../assets/default.exports";
import { Camera } from "../../components/default.exports";
import BasicHeader from "../../components/Headers/BasicHeader";
import BasicLoader from "../../components/Loaders/BasicLoader";
import { getUserFromLocalStorage } from "../../firebase/localStorage";
import { app_routes } from "../Routes";

type Props = {};

const Profile = (props: Props) => {
  const navigate = useNavigate();
  const [currentUser] = React.useState(getUserFromLocalStorage());
  const [cameraEnabled, setCameraEnabled] = React.useState(false);
  const [imageFile, setImageFile] = React.useState<any>(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const [loadingMessage, setLoadingMessage] = React.useState("Loading...");
  const imageFileName = "profile_picture";
  const profileUpdateForm = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      photoURL: ""
    },
    onSubmit: (values) => {}
  });

  React.useEffect(() => {
    if (currentUser) {
      const displayName = currentUser?.displayName.split(" ");
      profileUpdateForm.setFieldValue("first_name", displayName[0]);
      profileUpdateForm.setFieldValue("last_name", displayName[1]);
    }
  }, [currentUser]);

  return (
    <div>
      <BasicHeader
        title="Profile"
        fixed={true}
        isGoBack={true}
        goBackAction={() => {
          navigate(-1);
        }}
        menuButton={true}
      />
      <div className="p-3">
        <form onSubmit={profileUpdateForm.handleSubmit}>
          <div className="flex flex-col gap-[20px]">
            <Box className="flex justify-center">
              <Box className="flex justify-center relative">
                <img
                  src={
                    imageFile ? imageFile?.base64String : currentUser?.photoURL
                  }
                  width={150}
                  height={150}
                  onError={(ele) => {
                    ele.currentTarget.src = SelfiePlaceholder;
                  }}
                  onClick={() => {
                    setCameraEnabled(!cameraEnabled);
                  }}
                  className="rounded-full h-[150px] w-[150px] object-cover"
                  alt=""
                />
                <div className="absolute bottom-0 right-0">
                  <IconButton
                    color="primary"
                    aria-label="toggle password visibility"
                    onClick={() => {
                      setCameraEnabled(!cameraEnabled);
                    }}
                    type="button"
                  >
                    <CameraIcon />
                  </IconButton>
                </div>
              </Box>
              <input
                hidden
                id="photoURL"
                name="photoURL"
                accept="image/*"
                multiple
                type="file"
                value={profileUpdateForm.values.photoURL}
                onChange={profileUpdateForm.handleChange}
                onBlur={profileUpdateForm.handleBlur}
              />
            </Box>
            <TextField
              label="FIrst name"
              variant="outlined"
              type={"text"}
              id="first_name"
              name="first_name"
              value={profileUpdateForm.values.first_name}
              onChange={profileUpdateForm.handleChange}
              onBlur={profileUpdateForm.handleBlur}
              helperText={
                profileUpdateForm.errors.first_name &&
                profileUpdateForm.touched.first_name &&
                profileUpdateForm.errors.first_name
              }
              error={
                profileUpdateForm.errors.first_name &&
                profileUpdateForm.touched.first_name
                  ? true
                  : false
              }
              fullWidth
            />
            <TextField
              label="Last name"
              variant="outlined"
              type={"text"}
              id="last_name"
              name="last_name"
              value={profileUpdateForm.values.last_name}
              onChange={profileUpdateForm.handleChange}
              onBlur={profileUpdateForm.handleBlur}
              helperText={
                profileUpdateForm.errors.last_name &&
                profileUpdateForm.touched.last_name &&
                profileUpdateForm.errors.last_name
              }
              error={
                profileUpdateForm.errors.last_name &&
                profileUpdateForm.touched.last_name
                  ? true
                  : false
              }
              fullWidth
            />
          </div>
          <div className="mt-[20px]">
            <Button
              variant="contained"
              size="large"
              fullWidth
              color="primary"
              type="submit"
            >
              Update
            </Button>
          </div>
        </form>
        {cameraEnabled && (
          <Camera
            cameraEnabled={cameraEnabled}
            setCameraEnabled={setCameraEnabled}
            imageFile={imageFile}
            setImageFile={setImageFile}
            imageFileName={imageFileName}
          />
        )}
        {isLoading && <BasicLoader loadingMessage={loadingMessage} />}
      </div>
    </div>
  );
};

export default Profile;
