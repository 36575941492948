import {
  Google,
  VisibilityOff,
  Visibility,
  AddAPhoto
} from "@mui/icons-material";
import {
  Button,
  TextField,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  Typography,
  Box
} from "@mui/material";
import { useFormik } from "formik";
import React from "react";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { Camera } from "../../components/default.exports";
import { auth } from "../../firebase";
import { googleAuthAsync, manualSignupAsync } from "../../firebase/functions";
import BasicLoader from "../../components/Loaders/BasicLoader";
import { app_routes } from "../Routes";

type Props = {};

export type SignupFormType = {
  first_name: string;
  last_name: string;
  photoURL: any;
  email: string;
  department: string;
  role: string;
  password: string;
  confirm_password: string;
};

const SignupSchema = Yup.object().shape({
  first_name: Yup.string().required("First name is required").min(2),
  last_name: Yup.string().required("Last name is required").min(2),
  email: Yup.string().email("Invalid Email").required("Email is required"),
  password: Yup.string()
    .min(6, "Too short")
    .max(20, "Too long")
    .required("Password is required"),
  confirm_password: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Confirm password is required")
});

const Signup = (props: Props) => {
  const [showPassword, setShowPassword] = React.useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);
  const [cameraEnabled, setCameraEnabled] = React.useState(false);
  const [imageFile, setImageFile] = React.useState<any>(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const [loadingMessage, setLoadingMessage] = React.useState("Loading...");
  const imageFileName = "profile_picture";
  const signupForm = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      photoURL: "",
      email: "",
      department: "Mahavir Electronics",
      role: "employee",
      password: "",
      confirm_password: ""
    },
    validationSchema: SignupSchema,
    onSubmit: async (values) => {
      await manualSignup(values);
    }
  });

  const manualSignup = async (values: SignupFormType) => {
    // manual signup firebase service
    await manualSignupAsync(
      auth,
      values,
      imageFile,
      isLoading,
      setIsLoading,
      loadingMessage,
      setLoadingMessage
    );
    // hide loader
    setIsLoading(false);
  };

  const googleSignup = async () => {
    // google signup firebase service
    await googleAuthAsync(
      auth,
      isLoading,
      setIsLoading,
      loadingMessage,
      setLoadingMessage
    );
    // hide loader
    setIsLoading(false);
  };

  return (
    <div className="p-3 pt-10">
      <Button
        variant="contained"
        size="large"
        fullWidth
        color="secondary"
        startIcon={<Google />}
        onClick={googleSignup}
      >
        Signup With Google
      </Button>
      <div className="flex items-center justify-center py-3">
        <div className="border border-b border-black w-full"></div>
        <div className="px-2">OR</div>
        <div className="border border-b border-black w-full"></div>
      </div>
      <form onSubmit={signupForm.handleSubmit}>
        <div className="flex flex-col gap-[20px]">
          <TextField
            label="FIrst name"
            variant="outlined"
            type={"text"}
            id="first_name"
            name="first_name"
            value={signupForm.values.first_name}
            onChange={signupForm.handleChange}
            onBlur={signupForm.handleBlur}
            helperText={
              signupForm.errors.first_name &&
              signupForm.touched.first_name &&
              signupForm.errors.first_name
            }
            error={
              signupForm.errors.first_name && signupForm.touched.first_name
                ? true
                : false
            }
            fullWidth
          />
          <TextField
            label="Last name"
            variant="outlined"
            type={"text"}
            id="last_name"
            name="last_name"
            value={signupForm.values.last_name}
            onChange={signupForm.handleChange}
            onBlur={signupForm.handleBlur}
            helperText={
              signupForm.errors.last_name &&
              signupForm.touched.last_name &&
              signupForm.errors.last_name
            }
            error={
              signupForm.errors.last_name && signupForm.touched.last_name
                ? true
                : false
            }
            fullWidth
          />
          <TextField
            label="Email"
            variant="outlined"
            type={"email"}
            id="email"
            name="email"
            value={signupForm.values.email}
            onChange={signupForm.handleChange}
            onBlur={signupForm.handleBlur}
            helperText={
              signupForm.errors.email &&
              signupForm.touched.email &&
              signupForm.errors.email
            }
            error={
              signupForm.errors.email && signupForm.touched.email ? true : false
            }
            fullWidth
          />
          <Box>
            {!imageFile ? (
              <>
                <Button
                  variant="outlined"
                  size="large"
                  component="label"
                  fullWidth
                  startIcon={<AddAPhoto />}
                  onClick={() => {
                    setCameraEnabled(!cameraEnabled);
                  }}
                >
                  Profile Picture
                </Button>
                <input
                  hidden
                  id="photoURL"
                  name="photoURL"
                  accept="image/*"
                  multiple
                  type="file"
                  value={signupForm.values.photoURL}
                  onChange={signupForm.handleChange}
                  onBlur={signupForm.handleBlur}
                />
                {signupForm.errors.photoURL && signupForm.touched.photoURL && (
                  <div>
                    <Typography
                      component="p"
                      variant="caption"
                      color={"error"}
                      ml="15px"
                      mt="3px"
                    >
                      {signupForm.errors.photoURL}
                    </Typography>
                  </div>
                )}
              </>
            ) : (
              <>
                <div className="flex items-center justify-center">
                  <img
                    src={imageFile?.base64String}
                    className={"h-[150px] w-[100px] mb-2"}
                    style={{
                      objectFit: "cover"
                    }}
                    alt=""
                  />
                </div>
                <Button
                  variant="outlined"
                  size="large"
                  component="label"
                  fullWidth
                  onClick={() => {
                    setImageFile(null);
                  }}
                >
                  Reset
                </Button>
              </>
            )}
          </Box>
          <Box>
            <FormControl
              fullWidth
              variant="outlined"
              error={
                signupForm.errors.password && signupForm.touched.password
                  ? true
                  : false
              }
            >
              <InputLabel htmlFor="password">Password</InputLabel>
              <OutlinedInput
                id="password"
                type={showPassword ? "text" : "password"}
                fullWidth
                name="password"
                value={signupForm.values.password}
                onChange={signupForm.handleChange}
                onBlur={signupForm.handleBlur}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => {
                        setShowPassword(!showPassword);
                      }}
                      type="button"
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label="Password"
              />
            </FormControl>
            {signupForm.errors.password && signupForm.touched.password && (
              <div>
                <Typography
                  component="p"
                  variant="caption"
                  color={"error"}
                  ml="15px"
                  mt="3px"
                >
                  {signupForm.errors.password}
                </Typography>
              </div>
            )}
          </Box>
          <Box>
            <FormControl
              fullWidth
              variant="outlined"
              error={
                signupForm.errors.confirm_password &&
                signupForm.touched.confirm_password
                  ? true
                  : false
              }
            >
              <InputLabel htmlFor="confirm_password">
                Confirm Password
              </InputLabel>
              <OutlinedInput
                id="confirm_password"
                type={showConfirmPassword ? "text" : "password"}
                fullWidth
                name="confirm_password"
                value={signupForm.values.confirm_password}
                onChange={signupForm.handleChange}
                onBlur={signupForm.handleBlur}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => {
                        setShowConfirmPassword(!showConfirmPassword);
                      }}
                      type="button"
                      edge="end"
                    >
                      {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label="Confirm Password"
              />
            </FormControl>
            {signupForm.errors.confirm_password &&
              signupForm.touched.confirm_password && (
                <div>
                  <Typography
                    component="p"
                    variant="caption"
                    color={"error"}
                    ml="15px"
                    mt="3px"
                  >
                    {signupForm.errors.confirm_password}
                  </Typography>
                </div>
              )}
          </Box>
        </div>
        <div className="mt-[20px]">
          <Button
            variant="contained"
            size="large"
            fullWidth
            color="primary"
            type="submit"
          >
            Signup
          </Button>
        </div>
        <div className="flex items-center justify-center mt-2">
          <Typography color="primary" variant="body2">
            <Link to={app_routes?.signin.relative_pathname}>
              Already have an account? Signin.
            </Link>
          </Typography>
        </div>
      </form>
      {cameraEnabled && (
        <Camera
          cameraEnabled={cameraEnabled}
          setCameraEnabled={setCameraEnabled}
          imageFile={imageFile}
          setImageFile={setImageFile}
          imageFileName={imageFileName}
        />
      )}
      {isLoading && <BasicLoader loadingMessage={loadingMessage} />}
    </div>
  );
};

export default Signup;
