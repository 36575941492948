import { Button } from "@mui/material";
import { useFormik } from "formik";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { SelfiePlaceholder } from "../../assets/default.exports";
import { Camera } from "../../components/default.exports";
import BasicHeader from "../../components/Headers/BasicHeader";
import BasicLoader from "../../components/Loaders/BasicLoader";
import { PunchEnum } from "../../constants/enums";
import { auth, storage } from "../../firebase";
import {
  EmployeeDocumentType,
  getAllUsersAttendance,
  getAttendanceByUserId
} from "../../firebase/documents";
import { punchAsync } from "../../firebase/functions";
import { app_routes } from "../Routes";
import { Toast } from "../../components/Alerts/alertservice";
import { uploadBytes, getDownloadURL, ref } from "firebase/storage";

const mapboxgl = require("../../../node_modules/mapbox-gl/dist/mapbox-gl.js");
mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;

type Props = {};

export type PunchType = {
  user: EmployeeDocumentType;
  selfieURL: string;
  coords: {
    lng: number;
    lat: number;
  };
  location_address: string;
  punch_type: "checkin" | "checkout";
  date: Date;
  time: Date;
};

const locationIqBaseUrl = "https://us1.locationiq.com/v1/";

const Punch = (props: Props) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = React.useState(false);
  const [loadingMessage, setLoadingMessage] = React.useState("Loading...");
  const [headerTitle, setHeaderTitle] = React.useState<string>("");
  const [center, setCenter] = React.useState<any>();
  const [cameraEnabled, setCameraEnabled] = React.useState(false);
  const [imageFile, setImageFile] = React.useState<any>(null);
  const imageFileName = "selfie_image";
  const mapContainer = React.useRef(null);
  const map = React.useRef(null);

  const { type } = useParams();

  // formik
  const punchForm = useFormik({
    initialValues: {
      user: "",
      selfieURL: "",
      coords: {
        lng: 0,
        lat: 0
      },
      location_address: "",
      punch_type: type,
      date: "",
      time: ""
    },
    onSubmit: async (values) => {
      if (type === PunchEnum.checkin) {
        await punchAttendance();
      } else {
        await punchAttendance();
      }
    }
  });

  const punchAttendance = async () => {
    if (!imageFile?.file) {
      Toast.fire({
        icon: "error",
        title: "Please take a selfie"
      });
      return;
    }

    if (punchForm.values.location_address === "") {
      Toast.fire({
        icon: "error",
        title: "Please wait for location address..."
      });
      return;
    }
    if (auth) {
      // punch checkin
      setIsLoading(true);
      setLoadingMessage(
        type === PunchEnum.checkin ? "Checking in..." : "Checking Out..."
      );
      // check if already checked in/ out for same day
      const currentUsersAttendanceList = await getAttendanceByUserId(
        auth,
        auth?.currentUser?.uid
      );

      if (punchForm.values.punch_type === PunchEnum.checkin) {
        const checkedInData = currentUsersAttendanceList.filter(
          (data: any) =>
            new Date(data?.date).toLocaleDateString() ===
              new Date().toLocaleDateString() &&
            data?.punch_type === PunchEnum.checkin
        );
        if (checkedInData.length > 0) {
          Toast.fire({
            icon: "error",
            title: "Already checked in for the day"
          });
          navigate(app_routes?.home.relative_pathname);
          return;
        }
      } else {
        const hasNotCheckedIn = currentUsersAttendanceList.filter(
          (data: any) =>
            new Date(data?.date).toLocaleDateString() ===
              new Date().toLocaleDateString() &&
            data?.punch_type === PunchEnum.checkin
        );
        const checkedOutData = currentUsersAttendanceList.filter(
          (data: any) =>
            new Date(data?.date).toLocaleDateString() ===
              new Date().toLocaleDateString() &&
            data?.punch_type === PunchEnum.checkout
        );
        if (checkedOutData.length > 0) {
          Toast.fire({
            icon: "error",
            title: "Already checked out for the day"
          });
          navigate(app_routes?.home.relative_pathname);
          return;
        }
        if (!(hasNotCheckedIn?.length > 0)) {
          Toast.fire({
            icon: "error",
            title: "Please checkin first."
          });
          navigate(app_routes?.home.relative_pathname);
          return;
        }
      }

      // upload selfie image
      const selfieUrlId = `${auth.currentUser?.uid}__${new Date().getTime()}`;
      // upload selfie to storage
      const photoURLRef = ref(storage, `selfies/${selfieUrlId}.jpg`);
      setLoadingMessage("Uploading profile picture...");
      // upload profile picture to storage
      await uploadBytes(photoURLRef, imageFile?.file)
        .then(async () => {
          // get uploaded profile picture url
          await getDownloadURL(photoURLRef)
            .then(async (url) => {
              setLoadingMessage(
                type === PunchEnum.checkin
                  ? "Checking in..."
                  : "Checking Out..."
              );
              await punchAsync(punchForm, auth, url);
              setIsLoading(false);
              navigate(app_routes?.home.relative_pathname);
            })
            .catch((err) => {
              console.log(err);
              return;
            });
        })
        .catch((err) => {
          console.log(err);
          return;
        });
    }
  };

  // fetch location address
  const fetchLocaitonAddress = async (coords: any) => {
    try {
      await fetch(
        `${locationIqBaseUrl}reverse?key=${process.env.REACT_APP_LOCATIONIQ_REVERSE_GEOCODING_API_KEY}&lat=${coords.latitude}&lon=${coords.longitude}&format=json`,
        {
          method: "GET",
          mode: "cors",
          redirect: "follow"
        }
      )
        .then((res) => res.json())
        .then((res) => {
          punchForm.setFieldValue("location_address", res?.display_name);
          return res;
        })
        .catch((err) => {
          setIsLoading(false);
          console.log(err);
          return;
        });
    } catch (err) {
      setIsLoading(false);
      console.log(err);
      return;
    }
  };

  // fetch current position, lat lng
  const getCurrentPosition = async () => {
    try {
      setLoadingMessage("Fetching location...");
      // check if navigator exists else ask permission
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(async (position) => {
          // get location address based on lat lng
          setLoadingMessage("Fetching location address...");
          await fetchLocaitonAddress(position.coords).catch((err) => {
            setIsLoading(false);
            console.log(err);
            return;
          });
          punchForm.setFieldValue("coords", position);
          setCenter(position);
          setIsLoading(false);
        });
      } else {
        console.log("Geolocation is not supported by this browser.");
      }
    } catch (error) {
      alert(error + ". Please turn on Location Services...");
      await getCurrentPosition();
    }
  };

  // toggle camera
  const toggleCameraModal = () => {
    setCameraEnabled(!cameraEnabled);
  };

  React.useEffect(() => {
    setIsLoading(true);
    if (!type) {
      navigate(app_routes?.root?.pathname);
    } else {
      setHeaderTitle(type);
      punchForm.setFieldValue("punch_type", type);
    }

    // get current location
    getCurrentPosition();
  }, [type]);

  // generate map
  React.useEffect(() => {
    if (center) {
      if (map.current) return; // initialize map only once
      map.current = new mapboxgl.Map({
        container: mapContainer.current,
        style: "mapbox://styles/mapbox/streets-v12",
        center: [center.coords.longitude, center.coords.latitude],
        zoom: 14,
        interactive: false
      });

      const el = document.createElement("div");
      el.className = "marker";

      try {
        new mapboxgl.Marker(el)
          .setLngLat([center.coords.longitude, center.coords.latitude])
          .addTo(map.current);
      } catch (error) {
        console.log(error);
      }
    }
  }, [center]);

  return (
    <div>
      <BasicHeader
        isGoBack={true}
        goBackAction={() => {
          navigate(-1);
        }}
        title={headerTitle}
        fixed={true}
        menuButton={true}
      />
      <div className="p-3">
        <div className="selfie flex flex-col items-center gap-[20px]">
          <img
            src={imageFile ? `${imageFile?.base64String}` : SelfiePlaceholder}
            alt=""
            className="w-[180px] h-[220px] object-cover"
          />
          <Button
            variant="contained"
            color="primary"
            className="w-full"
            onClick={toggleCameraModal}
          >
            Take selfie
          </Button>
        </div>
        <div className="current-location mt-[20px] pb-[100px]">
          <h4 className="font-bold">Current Location:</h4>
          <button disabled={true} className="w-full">
            <div
              ref={mapContainer}
              className="map-container border h-[200px] w-full"
            />
          </button>
          {/* <div className="py-2">
            {punchForm.values.location_address && (
              <h6 className="font-bold">{punchForm.values.location_address}</h6>
            )}
          </div> */}
        </div>
        <div className="fixed bottom-0 left-0 right-0 p-3 z-10 bg-white">
          {type === PunchEnum.checkin ? (
            <Button
              variant="contained"
              color="primary"
              className="w-full"
              size="large"
              onClick={() => {
                punchForm.setFieldValue("date", new Date().toISOString());
                punchForm.setFieldValue("date", new Date().toISOString());
                punchAttendance();
              }}
            >
              Check In
            </Button>
          ) : (
            <Button
              variant="contained"
              color="error"
              className="w-full"
              size="large"
              onClick={() => {
                punchForm.setFieldValue("time", new Date().toISOString());
                punchForm.setFieldValue("time", new Date().toISOString());
                punchAttendance();
              }}
            >
              Check Out
            </Button>
          )}
        </div>
      </div>
      {cameraEnabled && (
        <Camera
          cameraEnabled={cameraEnabled}
          setCameraEnabled={setCameraEnabled}
          imageFile={imageFile}
          setImageFile={setImageFile}
          imageFileName={imageFileName}
        />
      )}
      {isLoading && <BasicLoader loadingMessage={loadingMessage} />}
    </div>
  );
};

export default Punch;
