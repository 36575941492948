import {
  Auth,
  createUserWithEmailAndPassword,
  OAuthCredential,
  signInWithEmailAndPassword,
  signInWithPopup,
  updateProfile,
  User
} from "firebase/auth";
import { uploadBytes, getDownloadURL, ref } from "firebase/storage";
import { db, storage } from "../firebase";
import { SigninFormType } from "../pages/auth/Signin";
import { SignupFormType } from "../pages/auth/Signup";
import {
  AttendanceDocumentType,
  createAttendanceDocument,
  createUserDocument,
  getUserDocument
} from "./documents";
import { GoogleAuthProvider } from "firebase/auth";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  orderBy,
  query,
  where
} from "firebase/firestore";
import { PunchEnum } from "../constants/enums";
import { setUserToLocalStorage } from "./localStorage";

const provider = new GoogleAuthProvider();

// manual signup
export const manualSignupAsync = async (
  auth: Auth,
  values: SignupFormType,
  imageFile: any,
  isLoading: boolean,
  setIsLoading: any,
  loadingMessage: string,
  setLoadingMessage: any
) => {
  try {
    let photoURL: string | null = null;

    setIsLoading(true);
    setLoadingMessage("Creating account...");
    // Create account
    await createUserWithEmailAndPassword(auth, values.email, values.password)
      .then(async (userCredential) => {
        const user = userCredential.user;
        const photoURLRef = ref(storage, `profiles/${user.uid}.jpg`);

        setLoadingMessage("Uploading profile picture...");
        // upload profile picture to storage
        await uploadBytes(photoURLRef, imageFile?.file).catch((err) => {
          console.log(err);
          return;
        });

        setLoadingMessage("Confirming...");
        // get uploaded profile picture url
        await getDownloadURL(photoURLRef)
          .then((url) => {
            photoURL = url;
          })
          .catch((err) => {
            console.log(err);
            return;
          });

        setLoadingMessage("Updating account details...");
        // update user profile
        await updateProfile(user, {
          displayName: values.first_name + " " + values.last_name,
          photoURL: photoURL
        })
          .then(() => {
            setLoadingMessage("Account created...");
            // Profile updated!
          })
          .catch((err) => {
            console.log(err);
            return;
          });

        // create user document
        setLoadingMessage("Logging in...");
        await createUserDocument(user, photoURL, values);
        setUserToLocalStorage(user);
      })
      .catch((err) => {
        console.log(err);
        return;
      });
  } catch (err) {
    console.log(err);
    return;
  }
};

// manual signin
export const manualSigninAsync = async (
  auth: Auth,
  values: SigninFormType,
  isLoading: boolean,
  setIsLoading: any,
  loadingMessage: string,
  setLoadingMessage: any
) => {
  try {
    setIsLoading(true);
    setLoadingMessage("Logging in...");
    // Create account
    await signInWithEmailAndPassword(auth, values.email, values.password)
      .then(async (userCredential) => {
        const user = userCredential.user;
      })
      .catch((err) => {
        console.log(err);
        return;
      });
  } catch (err) {
    console.log(err);
    return;
  }
};

// google signup/signin
export const googleAuthAsync = async (
  auth: Auth,
  isLoading: boolean,
  setIsLoading: any,
  loadingMessage: string,
  setLoadingMessage: any
) => {
  try {
    setIsLoading(true);
    setLoadingMessage("Google auth in progress...");
    // google signup/signin
    signInWithPopup(auth, provider)
      .then(async (result) => {
        // The signed-in user info.
        const user: User | any = result.user;
        const photoURL = result.user.photoURL;
        const displayName: any = result.user.displayName?.split(" ");

        const values: SignupFormType = {
          first_name: displayName[0],
          last_name: displayName[1],
          email: user.email,
          department: "Mahavir Electronics",
          photoURL: user.photoURL,
          role: "employee",
          confirm_password: "",
          password: ""
        };
        // Create user document
        await createUserDocument(user, photoURL, values);
      })
      .catch((err) => {
        console.log(err);
        return;
      });
  } catch (err) {
    console.log(err);
    return;
  }
};

// attendance punching
export const punchAsync = async (
  punchForm: any,
  authUser: any,
  selfieURL: string
) => {
  try {
    const docRef = doc(db, "employees", authUser?.currentUser.uid);
    const docSnap = await getDoc(docRef);
    let user: any = null;

    if (docSnap.exists()) {
      user = docSnap.data();
    } else {
      // doc.data() will be undefined in this case
      console.log("No such document!");
      return;
    }

    const punchDocumentData: any = {
      user: user,
      coords: {
        lat: punchForm.values.coords.coords.latitude,
        lng: punchForm.values.coords.coords.longitude
      },
      date: new Date().toISOString(),
      location_address: punchForm.values.location_address,
      punch_type: punchForm.values.punch_type,
      selfieURL: selfieURL,
      time: new Date().toISOString()
    };

    await createAttendanceDocument(authUser, punchDocumentData);
  } catch (err) {
    console.log(err);
    return;
  }
};
